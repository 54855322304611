import { getCookieValue } from './../../utilities/js/helper';

class SolutionfinderTrigger {
    constructor (element, options) {
        const defaults = {
            header: document.querySelector('[data-siteheader="root"]'),
            breadcrumbs: document.querySelector('[data-breadcrumb="root"]'),
            stage: document.querySelector('[data-stage]')
        };

        this.settings = Object.assign({}, defaults, options);
        this.waypointY = 0;
        this.firstLoad = true;
        this.solutionfinder = document.querySelector('[data-modal="solutionfinder"]');
        this.elementsForWayPoint = [
            this.settings.header,
            this.settings.stage,
            this.settings.breadcrumbs
        ];
    }

    initialize () {
        this.setEvents();
    }

    setEvents () {
        this.setWaypointYPosition();
    }

    setWaypointYPosition () {
        for (let i = 0; i < this.elementsForWayPoint.length; i++) {
            if (this.elementsForWayPoint[i] !== null) {
                this.waypointY = this.waypointY + this.elementsForWayPoint[i].clientHeight;
            }
        }

        window.eventScroller.customFunctions.push(() => {
            if (window.scrollPosition > this.waypointY && this.firstLoad) {
                this.showSolutionfinder();
            }
        });
    }

    showSolutionfinder () {
        this.firstLoad = false;
        this.solutionfinder.click();
        // COOKIE is set in modal.js line 193 !!!!!!!!!!!!!
    }
}

export { SolutionfinderTrigger };

window.addEventListener('content.loaded', (e) => {
    const eventDetails = e.detail;
    const $context = eventDetails.$context;

    if ($context) {
        const $sfTrigger = $context.querySelector('[data-solutionfinder="cookie"]');
        if ($sfTrigger && !getCookieValue('SOLUTIONFINDER')) {
            const sfTrigger = new SolutionfinderTrigger($sfTrigger);
            sfTrigger.initialize();
        }
    }
});
